import { useClickAway } from '@uidotdev/usehooks';
import { useState } from 'react';
import { DateRange } from 'react-date-range';
import { formatDate } from '../utils/helpers';

export const DateRangePicker = ({ label, width, fromDate, toDate, id, handleDate }: IDateRangePicker) => {

    const [open, setOpen] = useState(false)

    const ref: any = useClickAway(() => {
        setOpen(false);
    });

    const handleSelect = ({ selection }) => {
        handleDate(selection.startDate, selection.endDate, id)
    }

    const handleConfirm = (e) => {
        e.stopPropagation()
        setOpen(false)
    }

    return <div>
        <div ref={ref} className="input-container" onClick={() => setOpen(true)} style={width ? { width } : {}} >
            {label && <p className='input-container__label' >{label}</p>}
            <div className="input-container__input"  >
                {!fromDate ? <p className="placeholder" >{'Izaberite raspon'}</p> : <p className='input-container__input--value' >{formatDate(fromDate.toString(), true)} - {formatDate(toDate.toString(), true)} </p>}
                {open &&
                    <div className='daterange-wrapper' style={{ zIndex: 9999999 }} >
                        <DateRange
                            editableDateInputs={true}
                            onChange={(item: any) => handleSelect(item)}
                            moveRangeOnFirstSelection={false}
                            ranges={[{ startDate: fromDate, endDate: toDate, key: 'selection' }]}
                        />
                        <div className='confirm' onClick={handleConfirm}>OK</div>
                    </div>}
            </div>
        </div>
    </div>
}

interface IDateRangePicker {
    label: string
    width?: string
    fromDate: Date
    toDate: Date
    id: number
    handleDate: (fromDate: Date, toDate: Date, id: number) => any
}