/*eslint-disable*/
import { CSSProperties, useEffect, useRef, useState } from "react";
import { ClipLoader } from "react-spinners";
import Chevron from '../assets/icons/Chevron.svg'
import { IAgencyAdditionalService, IAgencySeason } from "../types/agencies.interface";
import customFetch from "../utils/axios";
import { ISelectValue } from "./Select";

export default function AsyncSelect({
    label,
    handleChange,
    value,
    placeholder,
    top,
    name,
    width,
    link,
    noPagination,
    fixedList,
    parserFunction,
    additionalParams,
    slash,
    data,
    invisible
}: AsyncISelect) {
    const [openOptions, setOpenOptions] = useState(false);
    const [options, setOptions] = useState<ISelectValue[]>([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [query, setQuery] = useState('')
    const ref = useRef(null)

    const handleCloseOptions = () => {
        setOpenOptions(false)
        setOptions([])
    }

    const handleSelect = (o: IAsyncSelectValue) => {
        handleChange(o, name)
        handleCloseOptions()
    }

    // const searchFixedList = () => {
    //     const list: any =
    //         fixedList &&
    //         fixedList.filter(({ label }) => String(label).toLowerCase().indexOf(query.toLowerCase()) > -1)
    //     setOptions(list)
    // }

    // const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    //     const { scrollHeight, scrollTop, offsetHeight } = e.currentTarget
    //     const bottom = scrollTop + offsetHeight >= scrollHeight
    //     if (bottom && !noPagination) {
    //         setPage((prev) => prev + 1)
    //     }
    // }

    // const handleQuery = (e: React.FormEvent<HTMLInputElement>) => {
    //     setOptions([])
    //     const { value } = e.target as HTMLInputElement
    //     setQuery((prev: string) => {
    //         // if (prev.length > value.length) {
    //         //     setSelected(false)
    //         // }
    //         return value
    //     })
    //     if (query.length > value.length) {
    //         handleChange({ label: '', value: '' }, name)
    //     }
    //     setPage(1)
    // }

    function additionalParameters() {
        if (additionalParams) {
            return Object.keys(additionalParams).map(key => `&${encodeURIComponent(key)}=${encodeURIComponent(additionalParams[key])}`).join('&');
        } else if (slash) {
            return '/' + slash
        }
        return '';
    }

    useEffect(() => {
        if (parserFunction && openOptions) {
            const fetchData = async () => {
                setLoading(true)

                if (link) {
                    const resp = await customFetch.get(link + (!noPagination ?
                        ('?page=' +
                            page +
                            '&limit=10' +
                            (query ? '&q=' + query : '')) : '') + additionalParameters())


                    setOptions((prev) => {
                        let copy = [...prev]
                        copy = [...copy, ...parserFunction(data ? resp.data.data : resp.data)].filter(({ label }) => label.toLowerCase().indexOf(query.toLowerCase()) > -1)
                        if (noPagination) {
                            copy = copy.filter(({ label }) => String(label).toLowerCase().indexOf(query.toLowerCase()) > -1)
                        }
                        return copy
                    })
                    setLoading(false)
                }
            }
            fetchData()
        }
    }, [page, query, additionalParams, openOptions])

    const calculateTop = () => {
        return '-' + (options.length === 1 ? 10 : options.length <= 5 ? options.length * 40 : 180)
    }


    const inv: CSSProperties = invisible ? { opacity: 0, userSelect: 'none', pointerEvents: 'none' } : {}
    return (
        <div className="input-container" style={{ width, ...inv, cursor: slash === 'null' ? 'not-allowed' : 'pointer' }} tabIndex={0} onBlur={handleCloseOptions} >
            {label && <p className="input-container__label" >{label}</p>}
            <div className="input-container__input" ref={ref} onClick={() => !(slash === 'null') && setOpenOptions(true)} >
                {!value || !value.value ? <p className="placeholder" >{placeholder ?? 'Odaberite'}</p> : <p className="input-container__input--value" >{value.label}</p>}
                <img src={Chevron} alt="" />
            </div>
            {openOptions && (
                <div className="select-options-container" style={top ? { top: calculateTop() + 'px' } : {}} >
                    {options?.map((o, k) => (
                        <div className="select-option" onClick={() => handleSelect(o)} key={k}>
                            {o.label}
                        </div>
                    ))}
                </div>
            )}
            <div className="input-container__loader" >
                <ClipLoader
                    color={'#ff9900'}
                    loading={loading}
                    size={150}
                    aria-label="Loading Spinner"
                    className="input-container__loader--spinner"
                    data-testid="loader"
                />
            </div>
        </div>
    );
}

interface AsyncISelect {
    label?: string;
    handleChange: (o: ISelectValue, name: string) => any;
    value: ISelectValue | null;
    placeholder?: string
    top?: boolean
    name: string
    width?: string
    parserFunction: (data: any) => any,
    noPagination?: boolean,
    link: string,
    fixedList?: ISelectValue[]
    additionalParams?: any,
    slash?: string | number
    data?: boolean
    invisible?: boolean
}

export interface IAsyncSelectValue extends ISelectValue {
    agencyAdditionalServices?: IAgencyAdditionalService[]
    agencySeasons?: IAgencySeason[]
}

export interface IAgenciesSelectValue extends ISelectValue {
    additionalServices: IAgencyAdditionalService[]
    seasons: IAgencySeason[]
}
