import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "../../components/Button"
import { ConfirmModal } from "../../components/ConfirmModal"
import { TableActions } from "../../components/TableActions"
import { clearRentPeriods, deleteRentPeriod, getRentPeriods } from "../../features/rent-periods/rentPeriodsSlice"
import { AppDispatch, RootState } from "../../store"
import { IRentPeriodsState } from "./data"
import { FormModal } from "./FormModal"

export const RentPeriods = () => {

    const dispatch = useDispatch<AppDispatch>()
    const { rentPeriods } = useSelector((state: RootState) => state.rentPeriods)
    const [deleteModal, setDeleteModal] = useState({ open: false, fn: null })
    const [modal, setModal] = useState<any>({ open: false, type: null, id: null, value: '' })

    useEffect(() => {
        dispatch(getRentPeriods())

        return (() => {
            dispatch(clearRentPeriods())
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleModal = (r: IRentPeriodsState) => {
        setModal({ open: true, value: r, id: r.id, type: 'editing' })
    }

    const handleDelete = (id: number) => {
        setDeleteModal({ open: true, fn: () => dispatch(deleteRentPeriod(id)) })
    }

    const handleCloseModal = () => {
        setDeleteModal({ open: false, fn: null })
    }


    return <div>
        <div className="page-top" style={{ justifyContent: 'flex-end' }} >
            <Button text="Dodaj raspon" onClick={() => setModal({ open: true, type: 'adding', id: null, value: null })} />
        </div>
        <div className="table-wrapper" >
            <table >
                <thead >
                    <th>ID</th>
                    <th >Od</th>
                    <th >Do</th>
                </thead>
                <tbody>
                    {rentPeriods.map((r, k) => <tr key={r.id}>
                        <td width='100px' >{r.id}</td>
                        <td width='100px' >{r.from}</td>
                        <td>{r.to}</td>
                        <TableActions zIndex={9 + k} options={[{ name: 'Izmeni', action: () => handleModal(r) }, { name: 'Obriši', action: () => handleDelete(r.id) }]} />
                    </tr>)}
                </tbody>
            </table>
            {modal.open && <FormModal value={modal.value} setModal={setModal} type={modal.type} />}

        </div>
        {deleteModal.open && <ConfirmModal text="obrišete raspon iznajmljivanja?" onYes={deleteModal.fn} closeModal={handleCloseModal} />}

    </div >
}