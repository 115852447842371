import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Button } from "../../components/Button"
import { ConfirmModal } from "../../components/ConfirmModal"
import { TableActions } from "../../components/TableActions"
import { clearReservations, deleteReservation, getReservations } from "../../features/reservations/reservationsSlice"
import { AppDispatch, RootState } from "../../store"
import { daysBetweenDates, formatDate, getAmPm } from "../../utils/helpers"
import { statuses } from "./data"

export const Reservations = () => {

    const dispatch = useDispatch<AppDispatch>()
    const { reservations } = useSelector((state: RootState) => state.reservations)
    const [deleteModal, setDeleteModal] = useState({ open: false, fn: null })
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(getReservations())

        return (() => {
            dispatch(clearReservations())
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDelete = (id: number) => {
        setDeleteModal({ open: true, fn: () => dispatch(deleteReservation(id)) })
    }

    const handleCloseModal = () => {
        setDeleteModal({ open: false, fn: null })
    }


    const getStatus = (status: number) => {
        const s = statuses.find(s => s.value === status)
        return <p style={{ paddingLeft: '0', color: s.color }} >{s.label}</p>
    }

    return <div>
        <div className="page-top" style={{ justifyContent: 'flex-end' }} >
            <Button text="Dodaj rezervaciju" onClick={() => navigate('add')} />
        </div>
        <div className="table-wrapper" >
            <table >
                <thead >
                    <th>Rez. ID</th>
                    <th >Vozilo</th>
                    <th  >Datum od</th>
                    <th  >Datum do</th>
                    <th  >Ukupno dana</th>
                    <th  >Cena</th>
                    <th  >Ocena</th>
                    <th >Status</th>
                </thead>
                <tbody>
                    {reservations.map((r, k) => <tr key={r.id}>
                        <td width='130px' >{r.id}</td>
                        <td>{r.vehicle.model.name} <br /> <span style={{ fontWeight: 'bold' }} >{r.vehicle.agency.name}</span></td>
                        <td>{formatDate(r.fromDate)} <br /> {getAmPm(r.fromDate)}</td>
                        <td>{formatDate(r.toDate)} <br /> {getAmPm(r.toDate)}</td>
                        <td width='150px' >{daysBetweenDates(new Date(r.fromDate), new Date(r.toDate))}</td>
                        <td>{r.price}</td>
                        <td>/</td>
                        <td>{getStatus(r.status)}</td>
                        <TableActions zIndex={9 + k} options={[{ name: 'Izmeni', action: () => navigate(String(r.id)) }, { name: 'Obriši', action: () => handleDelete(r.id) }]} />
                    </tr>)}
                </tbody>
            </table>
        </div>
        {deleteModal.open && <ConfirmModal text="obrišete rezervaciju?" onYes={deleteModal.fn} closeModal={handleCloseModal} />}

    </div>
}