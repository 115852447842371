import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { Button } from "../../components/Button"
import { Input } from "../../components/Input"
import { clearClients, editClient, getSingleClient } from "../../features/clients/clientsSlice"
import { AppDispatch, RootState } from "../../store"
import { IGetSingleClient } from "../../types/clients.interface"
import { changeState } from "../../utils/helpers"
import { standardFieldValidation } from "../../utils/validationUtils"

export const EditClient = () => {

    const [state, setState] = useState<IGetSingleClient>(null)
    const [invalidFields, setInvalidFields] = useState([])
    const dispatch = useDispatch<AppDispatch>()
    const { singleClient } = useSelector((state: RootState) => state.clients)
    const navigate = useNavigate()
    const { id } = useParams()

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        standardFieldValidation(e, setInvalidFields)
        changeState(setState, name, value)
    }

    useEffect(() => {
        dispatch(getSingleClient(id as string))

        return (() => {
            dispatch(clearClients())
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        if (singleClient) {
            setState(singleClient)
        }
    }, [singleClient])

    const handleSubmit = async () => {
        if (invalidFields.length > 0) {
            toast.warn('Sva polja moraju biti popunjena.')
            return
        }
        const resp = await dispatch(editClient(state))
        if (resp.meta.requestStatus === 'fulfilled') {
            navigate('/clients')
        }
    }

    return <div className="form-page" >
        <div className="form-grid" >
            <Input value={state?.firstName} name='firstName' label='Ime' handleChange={handleChange} />
            <Input value={state?.lastName} name='lastName' label='Prezime' handleChange={handleChange} />
            <Input value={state?.phoneNumber} name='phoneNumber' label='Broj telefona' handleChange={handleChange} />
            <Input value={state?.email} name='email' label='Email' handleChange={handleChange} />
            <Input value={state?.licenseNumber} name='licenseNumber' label='Broj vozačke' handleChange={handleChange} />
        </div>
        <div className="page-bottom" >
            <Button text="Sačuvaj" onClick={handleSubmit} />
        </div>
    </div>
}