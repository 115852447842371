import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AsyncSelect from "../../components/AsyncSelect";
import { Button } from "../../components/Button";
import { ISelectValue, } from "../../components/Select";
import {
    getMileage,
} from "../../features/side-data/sideDataSlice";
import { AppDispatch } from "../../store";
import { ILocalizedTextId } from "../../types/global.interface";
import { IGetSingleRentPeriod } from "../../types/rent-periods.interface";
import { changeState, formatStateRPM } from "../../utils/helpers";
import { IAgenciesState } from "./data";

export const MileageModal = ({ closeModal, rentPeriodMileagesState, setState }: IMileageModal) => {
    const dispatch = useDispatch<AppDispatch>();
    const [rentPeriodMileages, setRentPeriodMileages] = useState<IRentPeriodMileage[]>(formatStateRPM(rentPeriodMileagesState))

    useEffect(() => {
        dispatch(getMileage());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSave = () => {
        changeState(setState, 'rentPeriodMileage', rentPeriodMileages)
        closeModal()
    }


    const handleSelect = (value: ISelectValue, name: string, k: number) => {
        setRentPeriodMileages(prev => {
            const copy = structuredClone(prev)
            copy[k][name] = value
            return copy
        })
    }

    const renderRows = () => {
        return rentPeriodMileages.map((f, k) => {
            return <div className="mileage-modal__row">
                <div className="mileage-modal__row--title">
                    {k === 0 && <h3 className="form-subtitle">Dani</h3>}
                    <AsyncSelect width="285px" handleChange={(v, n) => handleSelect(v, n, k)} parserFunction={(data: IGetSingleRentPeriod[]) => data.map(d => ({ value: d.id, label: d.from + '-' + d.to })).filter(p => !rentPeriodMileages.some(rp => rp.rentPeriod?.value === p.value))} link="rent-period" value={f.rentPeriod} name='rentPeriod' />
                </div>
                <p
                    style={{
                        marginTop: k === 0 ? "1rem" : "-1rem",
                        fontFamily: "Arial",
                        color: "white",
                    }}
                >
                    __
                </p>
                <div className="mileage-modal__row--title">
                    {k === 0 && <h3 className="form-subtitle">Kilometraža</h3>}
                    <AsyncSelect width="285px" handleChange={(v, n) => handleSelect(v, n, k)} parserFunction={(data: ILocalizedTextId[]) => data.map(d => ({ value: d.id, label: d.localizedText }))} link="mileage" value={f.mileage} name='mileage' />
                </div>
            </div>
        }
        )
    }

    return (
        <div className="mileage-modal">
            <h3 className="form-subtitle">Raspon i kilometraža</h3>
            {renderRows()}
            <Button onClick={handleSave} text="Sačuvaj" />
        </div>
    );
};


interface IMileageModal {
    closeModal: () => any
    rentPeriodMileagesState: IRentPeriodMileage[]
    setState: React.Dispatch<React.SetStateAction<IAgenciesState>>
}

export interface IRentPeriodMileage {
    rentPeriod: ISelectValue
    mileage: ISelectValue
    agencyRentPeriodId?: number
    index: number
}