import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "../../components/Button"
import { ConfirmModal } from "../../components/ConfirmModal"
import { TableActions } from "../../components/TableActions"
import { clearAdditionalServices, deleteAdditionalService, getAdditionalServices } from "../../features/additional-services/additionalServicesSlice"
import { AppDispatch, RootState } from "../../store"
import { FormModal } from "./FormModa"

export const AdditionalServices = () => {

    const { additionalServices } = useSelector((state: RootState) => state.additionalServices)
    const dispatch = useDispatch<AppDispatch>()
    const [deleteModal, setDeleteModal] = useState({ open: false, fn: null })
    const [modal, setModal] = useState({ open: false, type: 'adding', id: null, value: '' })

    useEffect(() => {
        dispatch(getAdditionalServices())

        return (() => {
            dispatch(clearAdditionalServices())
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleModal = (a) => {
        setModal({ open: true, value: a.name, id: a.id, type: 'editing' })
    }

    const handleDelete = (id: number) => {
        setDeleteModal({ open: true, fn: () => dispatch(deleteAdditionalService(id)) })
    }

    const handleCloseModal = () => {
        setDeleteModal({ open: false, fn: null })
    }

    return <div>

        <div className="page-top" style={{ justifyContent: 'flex-end' }} >
            <Button text="Dodaj uslugu" onClick={() => setModal({ open: true, type: 'adding', id: null, value: '' })} />
        </div>

        <div className="table-wrapper" >
            <table >
                <thead >
                    <th>Naziv</th>
                </thead>
                <tbody>
                    {additionalServices.map((a, k) => <tr key={a.id} >
                        <td>{a.name}</td>
                        <TableActions zIndex={9 + k} options={[{ name: 'Izmeni', action: () => handleModal(a) }, { name: 'Obriši', action: () => handleDelete(a.id) }]} />
                    </tr>)}
                </tbody>

            </table>
            {modal.open && <FormModal value={modal.value} setModal={setModal} type={modal.type} id={modal.id} />}
        </div>
        {deleteModal.open && <ConfirmModal text="obrišete dodatnu uslugu?" onYes={deleteModal.fn} closeModal={handleCloseModal} />}
    </div>
}