import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import Chevron from "../assets/icons/Chevron.svg";
import customFetch from "../utils/axios";
import { ISelectValue } from "./Select";
/*eslint-disable*/
export const FilterSelect = ({
    label,
    options,
    handleChange,
    name,
    link,
    parserFunction,
    additionalParams,
    slash,
    noPagination,
    width,
    value,
    zIndex
}: IFilterSelect) => {
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(1)
    const [query, setQuery] = useState('')
    const [localOptions, setLocalOptions] = useState(options || [])
    const [loading, setLoading] = useState(false)

    function additionalParameters() {
        if (additionalParams) {
            return Object.keys(additionalParams).map(key => `&${encodeURIComponent(key)}=${encodeURIComponent(additionalParams[key])}`).join('&');
        } else if (slash) {
            return '/' + slash
        }
        return '';
    }

    const slashEmpty = () => slash == null && typeof slash == 'object'

    useEffect(() => {
        if (parserFunction && open) {
            setLoading(true)
            const fetchData = async () => {


                if (link) {
                    const resp = await customFetch.get(link + (!noPagination ?
                        ('?page=' +
                            page +
                            '&limit=10' +
                            (query ? '&q=' + query : '')) : '') + additionalParameters())

                    setLoading(false)
                    setLocalOptions((prev) => {
                        let copy = [...prev]
                        copy = [...copy, ...parserFunction(resp.data)].filter(({ label }) => label.toLowerCase().indexOf(query.toLowerCase()) > -1)
                        if (noPagination) {
                            copy = copy.filter(({ label }) => String(label).toLowerCase().indexOf(query.toLowerCase()) > -1)
                        }
                        return copy
                    })
                }

            }
            fetchData()
        }
    }, [page, query, additionalParams, open])

    const onChange = (
        value: { label: string | number; value: string | number },
        name: string,
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        e.stopPropagation();
        handleChange(value, name);
        setOpen(false);
    };

    return (
        <div
            className="filter-select"
            tabIndex={0}
            onBlur={() => setOpen(false)}
            onClick={() => !slashEmpty() && setOpen(true)}
            style={{ width, zIndex, cursor: slashEmpty() ? 'not-allowed' : 'pointer' }}
        >
            <p>{value?.label || label}</p> <img src={Chevron} alt="" />
            {open && (
                <div className="filter-select__options-wrapper">
                    {localOptions.map((o, k) => (
                        <div
                            key={k}
                            className="filter-select__options-wrapper---option"
                            onClick={(e) => onChange(o, name, e)}
                        >
                            {o.label}
                        </div>
                    ))}
                </div>
            )}
            <div className="input-container__loader" style={{ right: '20%' }}>
                <ClipLoader
                    color={'#ff9900'}
                    loading={loading}
                    size={150}
                    aria-label="Loading Spinner"
                    className="input-container__loader--spinner"
                    data-testid="loader"
                />
            </div>
        </div>
    );
};

interface IFilterSelect {
    label: string;
    options?: {
        label: string | number;
        value: string | number;
    }[];
    name: string;
    link?: string
    parserFunction?: (data: any) => any,
    noPagination?: boolean
    additionalParams?: any
    slash?: string | number
    width?: string
    value: ISelectValue
    handleChange: (o: ISelectValue, name: string) => any;
    zIndex?: number

}
