import React from 'react'
import ReactPaginate from 'react-paginate'
import { PaginationPrevious } from '../../assets/icons/PaginationPrevious'
import { PaginationNext } from '../../assets/icons/PaginationNext'


export const Pagination = ({ pageCount, setPage, forcePage }: TPagination) => {
    if (pageCount < 2) return <></>
    return (
        <div className='pagination-wrapper' >
            <ReactPaginate
                onPageChange={({ selected }) => setPage(selected + 1)}
                previousLabel={PaginationPrevious}
                nextLabel={PaginationNext}
                pageCount={pageCount || 4}
                containerClassName='pagination'
                pageClassName='page-item'
                pageLinkClassName='page-link'
                previousClassName='arrow-page'
                previousLinkClassName='page-link'
                nextClassName='arrow-page'
                nextLinkClassName='page-link'
                activeClassName='page-item-active'
                activeLinkClassName='page-link-active'
                breakLabel={false}
                forcePage={forcePage}
                disabledClassName='disabled'
                disabledLinkClassName='disabled'
            ></ReactPaginate>
        </div>
    )
}

interface TPagination {
    pageCount: number
    setPage: (n: number) => any
    forcePage: number
}