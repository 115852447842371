import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "../../components/Button"
import { CheckText } from "../../components/CheckText"
import { getAdditionalServices } from "../../features/additional-services/additionalServicesSlice"
import { AppDispatch, RootState } from "../../store"
import { changeState } from "../../utils/helpers"
import { IAgenciesState } from "./data"

export const AdditionalServicesModal = ({ state, closeModal, setState }: IAgencyAdditionalServicesModal) => {

    const { additionalServices } = useSelector((state: RootState) => state.additionalServices)
    const dispatch = useDispatch<AppDispatch>()
    const [localState, setLocalState] = useState<{ additionalServiceId: number, price: number }[]>(state.additionalServices)

    useEffect(() => {
        dispatch(getAdditionalServices())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSave = () => {
        changeState(setState, 'additionalServices', localState)
        closeModal()
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, valueAsNumber } = e.target
        const index = localState.findIndex(s => s.additionalServiceId === Number(id))
        setLocalState((prev) => {
            const copy = structuredClone(prev)
            if (index > -1) {
                copy[index].price = valueAsNumber
            } else {
                copy.push({ additionalServiceId: Number(id), price: valueAsNumber })
            }
            return copy
        })
    }

    const handleCheck = (id: string) => {
        setLocalState(prev => {
            let copy = structuredClone(prev)
            const index = copy.findIndex(as => as.additionalServiceId === Number(id))
            if (index < 0) {
                copy.push({
                    additionalServiceId: Number(id),
                    price: 0
                })
            } else {
                copy = copy.filter(s => s.additionalServiceId !== Number(id))
            }
            return copy
        })
    }

    const renderInputs = () => {

        return additionalServices.map(as => {
            const found = localState.find(s => s.additionalServiceId === as.id)
            return <CheckText width="30%" key={as.id} handleCheck={handleCheck} checked={Boolean(found)} id={String(as.id)} name={as.name} value={found?.price} input handleChange={handleChange} />
        })

    }


    return <div className="additional-services-modal" >
        <div className="additional-services-modal__content">
            <h3 className="form-subtitle" >Dodaci</h3>
            <div className="additional-services-grid" >
                {renderInputs()}
            </div>
            <Button onClick={handleSave} text="Sačuvaj" />
        </div>
    </div>
}

interface IAgencyAdditionalServicesModal {
    closeModal: () => any
    state: IAgenciesState
    setState: React.Dispatch<React.SetStateAction<IAgenciesState>>
}