import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { ConfirmModal } from "../../components/ConfirmModal"
import { TableActions } from "../../components/TableActions"
import { archiveRequest, clearRequests, deleteRequest, getRequests } from "../../features/requests/requestsSlice"
import { AppDispatch, RootState } from "../../store"
import { IGetSingleRequest } from "../../types/requests.interface"
import { formatDate, getAmPm } from "../../utils/helpers"

export const Requests = () => {

    const { requests } = useSelector((state: RootState) => state.requests)
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()
    const [modal, setModal] = useState({ open: false, fn: null, text: '' })

    useEffect(() => {
        dispatch(getRequests())

        return (() => {
            dispatch(clearRequests())
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDelete = (id: number) => {
        setModal({ open: true, fn: () => dispatch(deleteRequest(id)), text: 'obrišete zahtev?' })
    }

    const handleArchive = (id: number) => {
        setModal({ open: true, fn: () => dispatch(archiveRequest(id)), text: 'arhivirate zahtev?' })
    }


    const handleCloseModal = () => {
        setModal({ open: false, fn: null, text: '' })
    }


    const handleCreateReservation = (r: IGetSingleRequest) => {
        const data = {
            firstName: r.client.firstName,
            lastName: r.client.lastName,
            phoneNumber: r.client.phoneNumber,
            email: r.client.email,
            fromDate: r.fromDate,
            toDate: r.toDate
        }
        navigate('/reservations/add?' + new URLSearchParams(data).toString())
    }

    return <div>

        <div className="page-top" >
            {/* <FilterSelect handleChange={() => null} value={{ label: '', value: 2 }} label="Svi zahtevi" name="type" options={[{ label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' },]} /> */}
        </div>
        <div className="table-wrapper" >
            <table>
                <thead >
                    <th>ID zahteva</th>
                    <th  >Ime i prezime </th>
                    <th >Datum od</th>
                    <th  >Datum do</th>
                    <th >Lokacija</th>
                    <th >Cena</th>
                    <th  >Telefon</th>
                    <th  >Email</th>
                </thead>
                <tbody>
                    {requests.map((r, k) => <tr key={r.id} >
                        <td  >{r.id}</td>
                        <td  >{r.client.firstName + ' ' + r.client.lastName} </td>
                        <td>{formatDate(r.fromDate)} <br /> {getAmPm(r.fromDate)}</td>
                        <td  >{formatDate(r.toDate)} <br /> {getAmPm(r.toDate)}</td>
                        <td >{r.location}</td>
                        <td  >{r.priceFrom + ' - ' + r.priceTo}</td>
                        <td  >{r.client.phoneNumber}</td>
                        <td  >{r.client.email}</td>
                        <TableActions zIndex={9 + k} options={[{ name: 'Kreiraj rezervaciju', action: () => handleCreateReservation(r) }, { name: 'Arhiviraj', action: () => handleArchive(r.id) }, { name: 'Obriši', action: () => handleDelete(r.id) }]} />
                    </tr>)}
                </tbody>
            </table>
        </div>
        {modal.open && <ConfirmModal text={modal.text} onYes={modal.fn} closeModal={handleCloseModal} />}
    </div>
}