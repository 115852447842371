import React, { useState } from "react"
import AsyncSelect, { IAsyncSelectValue } from "../../components/AsyncSelect"
import Select, { ISelectValue } from "../../components/Select"
import { IAgencySeason, IGetSingleAgency } from "../../types/agencies.interface"
import { IVehicleDropDownItems } from "../../types/vehicles.interface"
import { vehiclesInitialState, vehiclesInvalidFields } from "./data"
import { TextArea } from "../../components/TextArea"
import { Input } from "../../components/Input"
import { TextDisplayButton } from "../../components/TextDisplayButton"
import { changeState } from "../../utils/helpers"
import { SeasonsModal } from "./SeasonsModal"
import { useDispatch, useSelector, } from "react-redux"
import { AppDispatch, RootState } from "../../store"
import { ImagePick } from "../../components/ImagePick"
import { INameId } from "../../types/global.interface"
import { Button } from "../../components/Button"
import { addVehicle } from "../../features/vehicles/vehiclesSlice"
import { useNavigate } from "react-router-dom"
import { standardFieldValidation, validateSelect } from "../../utils/validationUtils"
import { toast } from "react-toastify"
import { getAgencyRentPeriodMileage } from "../../features/agencies/agenciesSlice"

export const AddVehicle = () => {

    const [state, setState] = useState(vehiclesInitialState)
    const [modal, setModal] = useState({ open: false, seasonId: null })
    const [invalidFields, setInvalidFields] = useState(vehiclesInvalidFields)
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()
    const { agencyRentPeriodMileage } = useSelector((state: RootState) => state.agencies)


    //ZA ISPITATI ZASTO TRIGGERUJE KAD SE MENJA NESTO DRUGO ON TEXT INPUTA A NE AGENCIJA
    // useEffect(() => {
    //     state.agency?.value && dispatch(getAgencyRentPeriodMileage(Number(state.agency.value)))
    // }, [state.agency])


    const handleSave = async () => {
        if (invalidFields.length > 0) {
            toast.error("Sva polja moraju biti popunjena.")
            return
        }
        const resp = await dispatch(addVehicle(state))
        if (resp.meta.requestStatus === 'fulfilled') {
            navigate('/vehicles')
        }
    }

    const handleSelect = (value: ISelectValue | IAsyncSelectValue, name: string) => {
        validateSelect(value, name, setInvalidFields)

        if (name === 'brand') {
            setInvalidFields(prev => [...prev, 'model'])
            changeState(setState, 'model', null)
        }

        if (name === 'agency') {
            const obj = value
            //@ts-ignore
            const { agencySeasons } = obj
            //@ts-ignore
            delete obj.agencySeasons
            const seasons = (agencySeasons as IAgencySeason[]).map(s => ({ name: s.season.name, seasonId: s.id, rentPeriods: [] }))
            const offSeason = state.seasons.find(s => s.seasonId === 'null')
            changeState(setState, name, obj)
            changeState(setState, 'seasons', [offSeason, ...seasons])
            dispatch(getAgencyRentPeriodMileage(Number(value.value)))
        } else {
            changeState(setState, name, value)
        }

    }

    const yearsOptions = () => {
        const currentYear = new Date().getFullYear();
        return Array.from({ length: currentYear - 1989 }, (_, index) => ({
            label: 1990 + index,
            value: 1990 + index
        }));
    }

    const doorsOptions = () => {
        return Array.from({ length: 8 }, (_, index) => ({
            label: 1 + index,
            value: 1 + index
        }));
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value, name } = e.target
        standardFieldValidation(e, setInvalidFields)
        changeState(setState, name, value)
    }


    const handleClick = (seasonId) => {
        setModal({ open: true, seasonId })
    }

    const handleCloseModal = () => {
        setModal({ open: false, seasonId: null })
    }

    const handleImage = (img, name: string) => {
        setState(prev => {
            const copy = structuredClone(prev)
            copy.images[Number(name)] = img;
            return copy
        })
    }

    const renderSeasons = () => {
        return <>
            {state.seasons.map(s => <TextDisplayButton key={s.seasonId} onClick={() => handleClick(s.seasonId)} label={s.name} checked={s.rentPeriods.some(p => p.price)} text={'Cene ' + s.name} />)}
        </>
    }


    return <div className="form-page">
        <div className='form-page__columns-wrapper' >
            <div className="form-grid two-columns" >
                <AsyncSelect width="100%" parserFunction={(a: IGetSingleAgency[]) => a.map(a => ({ label: a.name, value: a.id, agencySeasons: a.agencySeasons }))} value={state.agency} label="Agencija" name="agency" handleChange={handleSelect} link='agencies' />
                <AsyncSelect width="100%" link="vehicle-dropdown-items" label="Marka" name="brand" value={state.brand} handleChange={handleSelect} parserFunction={(vdi: IVehicleDropDownItems) => vdi.brands.map(b => ({ label: b.name, value: b.id }))} />
                <AsyncSelect noPagination slash={state.brand?.value || 'null'} width="100%" link="model" label="Model" name="model" value={state.model} handleChange={handleSelect} parserFunction={(models: INameId[]) => models.map(m => ({ label: m.name, value: m.id }))} />
                <AsyncSelect width="100%" link="vehicle-dropdown-items" label="Menjač" name="transmissionType" value={state.transmissionType} handleChange={handleSelect} parserFunction={(vdi: IVehicleDropDownItems) => vdi.transmissionTypes.map(t => ({ label: t.localizedText, value: t.id }))} />
                <Select label="Godište" name="year" value={state.year} handleChange={handleSelect} options={yearsOptions()} />
                <Select label="Broj vrata" name="doors" value={state.doors} handleChange={handleSelect} options={doorsOptions()} />
                <Select label="Broj sedišta" name="seats" value={state.seats} handleChange={handleSelect} options={doorsOptions()} />
                <AsyncSelect width="100%" link="vehicle-dropdown-items" label="Tip" name="vehicleType" value={state.vehicleType} handleChange={handleSelect} parserFunction={(vdi: IVehicleDropDownItems) => vdi.vehicleTypes.map(t => ({ label: t.localizedText, value: t.id }))} />
                <AsyncSelect width="100%" link="vehicle-dropdown-items" label="Gorivo" name="fuelType" value={state.fuelType} handleChange={handleSelect} parserFunction={(vdi: IVehicleDropDownItems) => vdi.fuelTypes.map(f => ({ label: f.localizedText, value: f.id }))} />
                <AsyncSelect width="100%" link="vehicle-dropdown-items" label="Kilometrža" name="mileage" value={state.mileage} handleChange={handleSelect} parserFunction={(vdi: IVehicleDropDownItems) => vdi.mileages.map(m => ({ label: m.localizedText, value: m.id }))} />
                <Input type="number" handleChange={handleChange} value={state.deposit} label='Depozit' name='deposit' />
                {renderSeasons()}
            </div>
            <div>
                <div className="images-wrapper" >
                    <ImagePick src={state.images[0]} onChange={handleImage} name='0' />
                    <ImagePick src={state.images[1]} onChange={handleImage} name='1' />
                    <ImagePick src={state.images[2]} onChange={handleImage} name='2' />
                    <ImagePick src={state.images[3]} onChange={handleImage} name='3' />
                </div>
                <TextArea handleChange={handleChange} value={state.description} name='description' label="Opis" />
            </div>
        </div>
        <div className="page-bottom"  >
            <div>
                <Button text="Sačuvaj" onClick={handleSave} />
            </div>
        </div>
        {modal.open && <SeasonsModal rentPeriods={agencyRentPeriodMileage} closeModal={handleCloseModal} seasons={state.seasons} seasonId={modal.seasonId} setState={setState} />}
    </div>
}