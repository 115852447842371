import { IRentPeriodMileage } from "./MileageModal";

export const agenciesInitialState:IAgenciesState = {
    name:'',
    pib:'',
    phoneNumber:'',
    email:'',
    address:'',
    city:'',
    representative:'',
    additionalPricePerKilometer:0,
    insurance:{label:'',value:''},
    surcharge:0,
    additionalServices:[],
    rentPeriodMileage:[],
    seasons:[]
}

export const agenciesInvalidFields = Object.keys(agenciesInitialState).filter(f=>!['seasons','rentPeriodMileage','additionalServices','additionalPricePerKilometer','surcharge'].includes(f))

export interface IAgenciesState {
        name: string;
        pib: string;
        phoneNumber: string;
        email: string;
        address: string;
        city: string;
        representative: string;
        additionalPricePerKilometer: number;
        insurance: {
            label: string;
            value: string | number;
        };
        surcharge: number;
    additionalServices:{additionalServiceId:number,price:number, name:string}[];
        rentPeriodMileage: IRentPeriodMileage[];
        seasons:{
            id:number,
            name:string,
            fromDate:Date
            toDate:Date 
            agencySeasonId?:number
        }[]
}

