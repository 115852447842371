import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../utils/axios";
import { toast } from "react-toastify";
import { INameId } from "../../types/global.interface";
import { handleErrors } from "../../utils/helpers";


const initialState:IState = {
  isLoading: false,
  additionalServices: [],
};

interface IState{
  isLoading:boolean
  additionalServices:INameId[]
}

export const getAdditionalServices = createAsyncThunk(
  "additionalServices/getAll",
  async (_, thunkApi) => {
    try {
      const resp = await customFetch.get("additional-services");
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteAdditionalService = createAsyncThunk(
  "additionalServices/delete",
  async (id:number, thunkApi) => {
    try {
     await customFetch.delete("additional-services/"+id);
      return id
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);


export const addAdditionalService = createAsyncThunk(
  "additionalServices/add",
  async (name:string, thunkApi) => {
    try {
      const resp = await customFetch.post("additional-services",{name});
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const editAdditionalService = createAsyncThunk(
  "additionalServices/edit",
  async ({name,id}:{name:string,id:string}, thunkApi) => {
    try {
      const resp = await customFetch.patch("additional-services/"+id,{name});
      return {name:resp.data,id};
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const additionalServicesSlice = createSlice({
  name: "additionalServices",
  initialState,
  reducers: {
    clearAdditionalServices:(state:IState)=>{
      state.additionalServices = []
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getAdditionalServices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdditionalServices.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        state.additionalServices = payload
      })
      .addCase(getAdditionalServices.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
      .addCase(deleteAdditionalService.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAdditionalService.fulfilled, (state, { payload }: any) => {
        state.additionalServices = state.additionalServices.filter(a=>a.id!==payload)
        state.isLoading = false;
        toast.success('Dodatna usluga uspešno obrisana.')
      })
      .addCase(deleteAdditionalService.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
      .addCase(addAdditionalService.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addAdditionalService.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        state.additionalServices.push(payload)
        toast.success('Dodatak uspešno dodat.')
      })
      .addCase(addAdditionalService.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
      .addCase(editAdditionalService.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editAdditionalService.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        const index = state.additionalServices.findIndex(as=>as.id===payload.id)
        state.additionalServices[index] = payload.name
        toast.success('Dodatak uspešno izmenjen.')
      })
      .addCase(editAdditionalService.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
  },
});

export const {clearAdditionalServices} = additionalServicesSlice.actions

export default additionalServicesSlice.reducer;
