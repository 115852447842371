import { createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import customFetch from "../../utils/axios";
import { toast } from "react-toastify";
import { IAgencyRentPeriodMileage, IGetSingleAgency } from "../../types/agencies.interface";
import { IAgenciesState } from "../../views/Agencies/data";
import { RootState } from "../../store";
import { areDatesEqual, handleErrors, sortRentPeriodMileages } from "../../utils/helpers";

const initialState:IState = {
  isLoading: false,
  agencies: [],
  singleAgency:null,
  agencyRentPeriodMileage:[]
};

interface IState{
  isLoading:boolean
  agencies:IGetSingleAgency[]
  singleAgency:IAgenciesState
  agencyRentPeriodMileage:IAgencyRentPeriodMileage[]
}

export const getAgencies = createAsyncThunk(
  "agencies/getAll",
  async (_, thunkApi) => {
    try {
      const resp = await customFetch.get("agencies");
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteAgency = createAsyncThunk(
  "agencies/delete",
  async (id:number, thunkApi) => {
    try {
     await customFetch.delete("agencies/"+id);
      return id
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const editAgency = createAsyncThunk(
  "agencies/edit",
  async ({id,agency}:{id:string,agency:IAgenciesState}, thunkApi) => {
    try {

      const obj = {
        pib:agency.pib,
        name:agency.name,
        phoneNumber:agency.phoneNumber,
        email:agency.email,
        address:agency.address,
        city:agency.city,
        representative:agency.representative,
        additionalPricePerKilometer:Number(agency.additionalPricePerKilometer),
    }

    await customFetch.patch("agencies/"+id, obj);


    for( const as of agency.additionalServices){
      const foundOldAdditionalService = (thunkApi.getState() as RootState).agencies.singleAgency.additionalServices.find(s=>s.additionalServiceId === as.additionalServiceId)

      if(!foundOldAdditionalService){
          await customFetch.post("agencies/"+id+'/add/service', {additionalServiceId:as.additionalServiceId,price:as.price});
      }else if(foundOldAdditionalService.price !== as.price){
          await customFetch.patch("agencies/"+id+'/update/service/'+as.additionalServiceId, {price:as.price});
      }
    }

    for(const oldAs of (thunkApi.getState() as RootState).agencies.singleAgency.additionalServices){
      const foundNewAs = agency.additionalServices.find(s=>s.additionalServiceId === oldAs.additionalServiceId)
      if(!foundNewAs){
        await customFetch.delete("agencies/"+id+'/remove/service/'+oldAs.additionalServiceId);
      }
    }

    for(const rpm of agency.rentPeriodMileage){

      const foundOldIndex = (thunkApi.getState() as RootState ).agencies.singleAgency.rentPeriodMileage.find(r=>r.index === rpm.index)
      if(foundOldIndex && foundOldIndex.rentPeriod.value !== rpm.rentPeriod.value){
        await customFetch.delete('/agency-rent-period-mileage/'+rpm.agencyRentPeriodId);
        await customFetch.post('/agency-rent-period-mileage/',{mileageId:rpm.mileage?.value, rentPeriodId:rpm.rentPeriod.value,agencyId:Number(id)});
        return
      }



      const foundOldRentPeriodMileage = (thunkApi.getState() as RootState ).agencies.singleAgency.rentPeriodMileage.find(r=>r.agencyRentPeriodId === rpm.agencyRentPeriodId)
      if( foundOldRentPeriodMileage && (foundOldRentPeriodMileage?.mileage?.value !== rpm?.mileage?.value)){
        await customFetch.patch('/agency-rent-period-mileage/'+rpm.agencyRentPeriodId,{mileageId:rpm.mileage?.value});
      }else if(!foundOldRentPeriodMileage && rpm.mileage?.value){
        await customFetch.post('/agency-rent-period-mileage/',{mileageId:rpm.mileage?.value, rentPeriodId:rpm.rentPeriod.value,agencyId:Number(id)});
      }
    }


    for(const s of agency.seasons){
      const old = (thunkApi.getState() as RootState ).agencies.singleAgency.seasons.find(ss=>ss.id === s.id)
      if(!areDatesEqual(old.fromDate, s.fromDate) || !areDatesEqual(old.toDate, s.toDate)){
        await customFetch.patch('/agencies-seasons/'+s.agencySeasonId,{fromDate:s.fromDate, toDate:s.toDate});
      }
    }

      return id
    } catch (error) {
      console.log(error)
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getAgencyRentPeriodMileage = createAsyncThunk(
  "agencies/getAgencyRentPeriodMileage",
  async (id:number, thunkApi) => {
    try {
      const resp = await customFetch.get('agency-rent-period-mileage/agency/'+id)
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getSingleAgency = createAsyncThunk(
  "agencies/getSingle",
  async (id:string, thunkApi) => {
    try {
      const resp = await customFetch.get("agencies/"+id);
      const rentPeriods = await customFetch.get('agency-rent-period-mileage/agency/'+id)

      const agencyData:IGetSingleAgency = resp.data
      const rentPeriodsData:IAgencyRentPeriodMileage[] = rentPeriods.data

      const formattedForState:IAgenciesState ={
        name:agencyData.name,
        pib:agencyData.pib,
        phoneNumber:agencyData.phoneNumber,
        email:agencyData.email,
        address:agencyData.address,
        city:agencyData.city,
        representative:agencyData.representative,
        additionalPricePerKilometer:agencyData.additionalPricePerKilometer,
        insurance:{
          label:agencyData?.agencyInsuranceType?.insuranceType?.localizedText,
          value:agencyData?.agencyInsuranceType?.insuranceType?.id
        },
        surcharge:agencyData.agencyInsuranceType?.surcharge,
        additionalServices:agencyData.agencyAdditionalServices.map(as=>({name:as.additionalService.name,additionalServiceId:as.additionalService.id, price:as.price})),
        seasons:agencyData.agencySeasons.map(as=>({id:as.season.id,name:as.season.name,fromDate:new Date(as.fromDate),toDate:new Date(as.toDate), agencySeasonId:as.id})),
        rentPeriodMileage:sortRentPeriodMileages(rentPeriodsData.map((rp,k)=>({agencyRentPeriodId:rp.id,index:k,rentPeriod:{value:rp.rentPeriod.id, label:rp.rentPeriod.from+'-'+rp.rentPeriod.to},mileage:{value:rp.mileage.id,label:rp.mileage.localizedText}})))
      }

      return formattedForState;
    } catch (error) {
      console.log(error)
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const addAgency = createAsyncThunk(
  "agencies/add",
  async (agency:IAgenciesState, thunkApi) => {

    const obj = {
        pib:agency.pib,
        name:agency.name,
        phoneNumber:agency.phoneNumber,
        email:agency.email,
        address:agency.address,
        city:agency.city,
        representative:agency.representative,
        additionalPricePerKilometer:Number(agency.additionalPricePerKilometer),
        additionalServices:agency.additionalServices,
        agencySeasons:agency.seasons.map(s=>({seasonId:s.id, fromDate:s.fromDate,toDate:s.toDate})),
        agencyInsuranceType:{insuranceTypeId:agency.insurance.value, surcharge:Number(agency.surcharge)}
    }

    try {
      const resp = await customFetch.post("agencies",obj);
      for(let m of agency.rentPeriodMileage){
        if(m.rentPeriod && m.mileage){
          await customFetch.post("agency-rent-period-mileage",{
            agencyId:resp.data.id,
            rentPeriodId:m.rentPeriod.value,
            mileageId:m.mileage.value
          })
        }
      }
    } catch (error) {
      console.log(error)
      return thunkApi.rejectWithValue(error);
    }
  }
);

const agenciesSlice = createSlice({
  name: "agencies",
  initialState,
  reducers: {
    clearAgencies:(state:IState)=>{
      state.agencies = []
      state.singleAgency = null
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getAgencies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAgencies.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        state.agencies = payload
      })
      .addCase(getAgencies.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
      .addCase(editAgency.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editAgency.fulfilled, (state) => {
        state.isLoading = false;
        toast.success('Agencija uspešno izmenjena.')
      })
      .addCase(editAgency.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        console.log(payload)
        handleErrors(payload)
      })
      .addCase(deleteAgency.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAgency.fulfilled, (state, { payload }: any) => {
        state.agencies = state.agencies.filter(a=>a.id!==payload)
        toast.success('Agencija uspešno obrisana.')
        state.isLoading = false;
      })
      .addCase(deleteAgency.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
      .addCase(addAgency.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addAgency.fulfilled, (state: any) => {
        state.isLoading = false;
        toast.success('Agencija uspešno dodata.')
      })
      .addCase(addAgency.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        console.log(payload)
        handleErrors(payload)
      })
      .addCase(getSingleAgency.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleAgency.fulfilled, (state: any, {payload}) => {
        state.isLoading = false;
        state.singleAgency = payload
      })
      .addCase(getSingleAgency.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
      .addCase(getAgencyRentPeriodMileage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAgencyRentPeriodMileage.fulfilled, (state: any, {payload}) => {
        state.isLoading = false;
        state.agencyRentPeriodMileage = payload
      })
      .addCase(getAgencyRentPeriodMileage.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
  },
});

export const {clearAgencies} = agenciesSlice.actions

export default agenciesSlice.reducer;
